<template>
	<div class="aboutus">
		<site-header></site-header>
		<header-search></header-search>
		<bread-line :showLevel="1"></bread-line>

		<div class="content">
			<div class="contactus">
				<blue-title>联系我们</blue-title>
				<div class="listbox">
					<div class="contactitem" v-for="item in sitelist" :key="item.id">
						<div class="name">{{ item.company_name }}</div>
						<div class="address">
							<div class="lefttitle">地址：</div>
							<div class="rightcon">{{ item.address }}</div>
						</div>
						<div class="telphone">
							<div class="lefttitle">总机：</div>
							<div class="rightcon">{{ item.phone }}</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<!-- showLevel   //0 最后一级   1 第一级  2 两级 -->

		<index-footer></index-footer>
	</div>
</template>

<script>
import SiteHeader from '@/components/website/SiteHeader.vue'
import BlueTitle from '@/components/website/BlueTitle.vue'
import HeaderSearch from '@/components/website/HeaderSearch.vue'

import IndexFooter from '@/components/website/IndexFooter.vue'
import BreadLine from '@/components/website/BreadLine.vue'
import NavMenu from '@/components/website/NavMenu.vue'
import { contact } from '@/api/website/site'
export default {
	name: 'PcWebsiteIndex',
	components: { SiteHeader, HeaderSearch, NavMenu, IndexFooter, BreadLine, BlueTitle },

	data() {
		return {
			sitelist: []
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	mounted() {

		this.list()
	},

	methods: {
		list() {
			contact().then(res => {
				this.sitelist = res.data
			})
		},
		tourl(path) {
			this.$router.push(path)
		}

	},
};
</script>

<style lang="scss" scoped>
.aboutus {
	background: #F0F3F8;
}

.content {
	width: 100%;
	height: auto;

	.contactus {
		width: 1200px;
		min-height: 200px;
		background: #fff;
		margin: 0 auto;
	}

	.listbox {
		width: 1190px;
		margin-left: 10px;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;

		.contactitem {
			margin-left:20px;
			width: 366px;
			height: 110px;
			background: #F2F2F2;
			padding: 10px 14px;
			box-sizing: border-box;
			margin-bottom: 20px;
			// display: flex;
			// justify-content: flex;

			.name {
				height: 22px;
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #5B6980;
				line-height: 22px;
				display: flex;

			}

			.address {
				margin-top:4px;
				height: 40px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				line-height: 20px;
				display: flex;
			}

			.telphone {
				margin-top:4px;
				height: 20px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				line-height: 20px;
				display: flex;

			}

			.lefttitle {
				width: 50px;
				height: 20px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				line-height: 20px;
				text-align: left;
			}
			.rightcon{
				text-align: left;
				width:280px;
			}
		}


	}
}
</style>